export class DeferredPromise {
    public ResolvePromise: () => void;
    public RejectPromise: (error: any) => void;
    public Promise: Promise<void>;

    constructor () {
        this.Promise = new Promise<void>((resolve, reject) => {
            this.ResolvePromise = resolve;
            this.RejectPromise = reject;
        });
    }
}

export class Lock {
    private waiters: DeferredPromise[];
    private locked: boolean;

    public constructor () {
        this.waiters = [];
        this.locked = false;
    }

    public async WithLockAsync (run: () => Promise<void>) {
        await this.Lock();
        try {
            await run();
        } catch (ex) {
            this.Unlock();

            throw ex;
        }

        this.Unlock();
    }

    public Lock (): Promise<void> {
        if (!this.locked) {
            this.locked = true;
            return new Promise(resolve => {
                resolve();
            });
        }

        let waiter = new DeferredPromise();
        this.waiters.push(waiter);

        return waiter.Promise;
    }

    public Unlock () {
        if (!this.locked) {
            throw new Error("Tried to unlock with no lock set.");
        }

        if (this.waiters.length > 0) {
            let waiter = this.waiters.shift();
            waiter.ResolvePromise();
        } else {
            this.locked = false;
        }
    }
}
