import * as WdLib from "./wdjslib";
import * as WorkThread from "./mobile_worker";

import { WorldGenerator } from "./WorldGenerator";
import { Lock } from "./Lock";
import * as JQuery from "jquery";

interface Vector3 {
    x?: number;
    y?: number;
    z?: number;
}

export interface LocationInstance {
    pos: Vector3;
    label?: string;
}

interface FeatheredLocation {
    Prefab: string;
    Placed: number;
    x: number;
    y: number;
    z: number;
}

interface ProgressType {
    Progress: number;
    Name: string;
}

declare global {
    function AsyncWorldGenIssueGeneralAsyncSetup(
        setupCallback: () => void,
        setupWorldCallback: (asyncId: string) => void,
        errorCallback: (error: string) => void,
        locationsCallback: (asyncId: string, featheredLocations: string) => void
    ): void;

    function AsyncWorldGenIssueAsyncWorldSetup(
        asyncId: string,
        seed: string,
        worldGenVersion: number,
        version: string
    ): void;

    function AsyncWorldGenGetProgress(): ProgressType;

    function AsyncWorldGenCancel(): void;
}

let worldGen: WorldGenerator = null;
let localInitCallback: () => void;
let localErrorCallback: (error: string) => void;
let localLocationsCallback: (
    asyncId: string,
    featheredLocations: string
) => void;
let localWorldSetupCallback: (asyncId: string) => void;
let progressWorkingOn: string = null;
let progressCurrentProgress: number = 100;
let jobAsyncId: string = null;

let worldGenError: string = null;
let worldGenLock = new Lock();

window.AsyncWorldGenCancel = function () {
    ReportErrorsAsync(() =>
        worldGenLock.WithLockAsync(async () => {
            // Invalidate any incoming segments
            jobAsyncId = null;

            // Tell the async thread to not generate anymore segments
            worldGen.Stop();
        })
    );
};

window.AsyncWorldGenIssueAsyncWorldSetup = function (
    asyncId: string,
    seed: string,
    worldGenVersion: number,
    version: string
) {
    ReportErrorsAsync(() =>
        worldGenLock.WithLockAsync(async () => {
            // Invalidate any incoming segments and switch to our asyncId
            jobAsyncId = asyncId;

            await worldGen.GenerateSeed(seed, worldGenVersion, version, true);

            await worldGen.StartZones();
        })
    );
};

window.AsyncWorldGenIssueGeneralAsyncSetup = function (
    setupCallback: () => void,
    setupWorldCallback: (asyncId: string) => void,
    errorCallback: (error: string) => void,
    locationsCallback: (asyncId: string, featheredLocations: string) => void
) {
    localInitCallback = setupCallback;
    localWorldSetupCallback = setupWorldCallback;
    localErrorCallback = errorCallback;
    localLocationsCallback = locationsCallback;

    // Call init if appropriate
    if (worldGenError) {
        localErrorCallback(worldGenError);
    } else if (worldGen) {
        localInitCallback();
    }
};

window.AsyncWorldGenGetProgress = function () {
    return {
        Progress: progressCurrentProgress,
        Name: progressWorkingOn,
    };
};

async function ReportErrorsAsync(run: () => Promise<void>) {
    try {
        await run();
    } catch (ex) {
        localErrorCallback(ex.toString());
    }
}

function ReportErrors(run: () => void) {
    try {
        run();
    } catch (ex) {
        localErrorCallback(ex.toString());
    }
}

function ProcessZoneSegment(zones: WorkThread.WorldLocationPayload) {
    ReportErrors(() => {
        let locations = zones.locations;
        let newFeatheredList = <FeatheredLocation[]>[];

        for (let location of locations.m_entries) {
            let locationPrefab = locations.m_locationTypes[location.m_location];

            // We let the binay reinterpret the integers back to floats
            newFeatheredList.push({
                x: location.m_x,
                y: location.m_y,
                z: location.m_z,
                Prefab: locationPrefab,
                Placed: 0,
            });
        }

        // No async id means this work was cancelled
        if (!jobAsyncId) {
            return;
        }

        localLocationsCallback(jobAsyncId, JSON.stringify(newFeatheredList));
    });
}

$(async function () {
    try {
        let gen = new WorldGenerator(true, "mobile-bridge/");

        gen.OnWorkProgressUpdate = (progressName: string, progress: number) => {
            progressWorkingOn = progressName;
            progressCurrentProgress = progress;
        };

        gen.OnWorkComplete = () => {};
        gen.OnZonesCalculated = ProcessZoneSegment;
        gen.OnWorldPregenStarted = () => localWorldSetupCallback(jobAsyncId);

        await gen.Init();
        worldGen = gen;
    } catch (ex) {
        console.error("Failed to setup asynchronous generation.");
        console.error(ex);
        worldGenError = ex.toString();
    }

    if (localInitCallback) {
        if (worldGen !== null) {
            localInitCallback();
        } else {
            localErrorCallback(worldGenError);
        }

        worldGenError = null;
    }
});
