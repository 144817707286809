import * as WorkThread from "./mobile_worker";
import * as Sentry from "@sentry/browser";
import type { Viewport } from "./Viewport";

interface CapturedRequest {
    resolve: (payload: any) => void;
    reject: (payload: any) => void;
}

export enum WorkType {
    AllWork,
    DrawWork,
    ZoneWork,
}

export class WorldGenerator {
    public OnSegmentDrawn?: (section: WorkThread.WorldRenderSection) => void;
    public OnZonesCalculated?: (
        locations: WorkThread.WorldLocationPayload
    ) => void;
    public OnWorkProgressUpdate: (
        progressName: string,
        percent: number
    ) => void;
    public OnWorkComplete: (work: WorkType) => void;
    public OnWorldPregenStarted?: () => void;

    private _worker: Worker;
    private _pendingRequests: { [requestName: string]: CapturedRequest };
    private _pendingDraw: number;
    private _pendingZoneGen: number;
    private _desktopMode: boolean;

    constructor (desktopMode?: boolean, prefix?: string) {
        let urlPrefix = "";
        if (prefix) {
            urlPrefix = prefix;
        }
        this._worker = new Worker(
            `${urlPrefix}mobile/mobile_worker_${MobileVersion}.js`
        );

        this.OnSegmentDrawn = null;
        this.OnZonesCalculated = null;
        this.OnWorkProgressUpdate = null;
        this.OnWorkComplete = null;
        this.OnWorldPregenStarted = null;

        this._desktopMode = !!desktopMode;
        this._pendingRequests = {};
        this._pendingDraw = null;
        this._pendingZoneGen = null;
        this._worker.onmessage = evt => {
            let message = evt.data;
            let segmentDropped = false;
            switch (message.msgType) {
                case "requestcomplete":
                    let pending = this._pendingRequests[message.request];
                    if (!pending) {
                        throw new Error(
                            "Unknown request completion from worker: " +
                                message.request
                        );
                    }

                    delete this._pendingRequests[message.request];

                    if (message.success) {
                        pending.resolve(message.payload);
                    } else {
                        pending.reject(message.payload);
                    }

                    break;
                case "worksegment":
                    // Verify this work segment is attached to pending work
                    if (
                        message.segmentThread !== this._pendingDraw &&
                        message.segmentThread !== this._pendingZoneGen
                    ) {
                        segmentDropped = true;
                        console.warn(
                            `Dropping unknown work segment ${message.segmentThread}`
                        );
                        this._worker.postMessage({
                            msgType: "dropsegment",
                            segment: message.segmentThread,
                        });
                        // We keep executing because just because a segment was dropped, doesn't
                        // mean one of the other work items shouldn't continue.
                    }

                    // Instantly respond so the next unit of work starts immediately
                    this._worker.postMessage({
                        msgType: "worksegment",
                    });

                    if (segmentDropped) {
                        break;
                    }

                    // Now process the work we've received
                    if (message.finished) {
                        try {
                            if (message.segmentThread === this._pendingDraw) {
                                this.OnWorkComplete(WorkType.DrawWork);
                            } else if (
                                message.segmentThread === this._pendingZoneGen
                            ) {
                                this.OnWorkComplete(WorkType.ZoneWork);
                            } else {
                                console.warn(
                                    `Unknown finished segment id: ${message.segmentThread}`
                                );
                            }
                        } catch (ex) {
                            console.error("Error processing work segment.");
                            console.error(ex);
                            if (Sentry) Sentry.captureException(ex);
                        }
                    } else {
                        try {
                            if (message.segmentThread === this._pendingDraw) {
                                this.OnSegmentDrawn(message.work);
                            } else if (
                                message.segmentThread === this._pendingZoneGen
                            ) {
                                this.OnZonesCalculated(message.work);
                            } else {
                                console.warn(
                                    `Unknown work segment id: ${message.segmentThread}`
                                );
                            }
                        } catch (ex) {
                            console.error("Error processing work segment.");
                            console.error(ex);
                            if (Sentry) Sentry.captureException(ex);
                        }
                    }

                    break;
                case "progress":
                    if (this.OnWorkProgressUpdate) {
                        this.OnWorkProgressUpdate(
                            message.name,
                            message.percentage
                        );
                    }
                    break;
                case "allworkcomplete":
                    console.log("All work complete. Thread idle now.");
                    this.OnWorkComplete(WorkType.AllWork);
                    break;
                case "pushdownload": {
                    let blob = null;

                    if (typeof message.obj === "string") {
                        blob = new Blob([message.obj], { type: "text/plain" });
                    } else {
                        blob = new Blob([message.obj], { type: "image/png" });
                    }

                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = message.name;
                    link.click();
                    break;
                }
                case "pregeneratefailed":
                    ($("#exception-during-work-modal") as any).modal("show");
                    break;
                case "pregeneratestarted":
                    if (this.OnWorldPregenStarted) {
                        this.OnWorldPregenStarted();
                    }
                    break;
                default:
                    throw new Error(
                        "Unknown message type from worker: " + message.msgType
                    );
            }
        };
    }

    async _WorkerRequest (name: string, payload: any): Promise<any> {
        if (name in this._pendingRequests) {
            throw new Error(`Duplicate requests for work ${name}`);
        }

        return await new Promise((resolve, reject) => {
            this._pendingRequests[name] = {
                resolve,
                reject,
            };

            this._worker.postMessage({
                msgType: "request",
                request: name,
                data: payload,
            });
        });
    }

    Init () {
        return this._WorkerRequest("start", {
            version: MobileVersion,
            desktopMode: this._desktopMode,
        });
    }

    Stop () {
        this._pendingDraw = null;
        this._pendingZoneGen = null;
    }

    async GenerateSeed (
        seed: string,
        worldGenVersion: number,
        worldVersion: string,
        isCompositeDisabled: boolean
    ) {
        this._pendingDraw = null;
        this._pendingZoneGen = null;

        await this._WorkerRequest("generateseed", {
            seed,
            worldGenVersion,
            worldVersion,
            isCompositeDisabled,
        });
        console.log("Seed done");
    }

    async StartDraw (layers: WorkThread.GenerationLayerDescriptor[]) {
        console.log("Starting draw...");
        this._pendingDraw = await this._WorkerRequest("startdraw", {
            layers,
            prefs: GetPrefs(),
        });

        this._worker.postMessage({
            msgType: "newworksegment",
        });
    }

    async StartZones () {
        this._pendingZoneGen = await this._WorkerRequest("startzones", {});

        this._worker.postMessage({
            msgType: "newworksegment",
        });
    }

    async LookupPopupDetails (
        locationX: number,
        locationZ: number
    ): Promise<string> {
        return this._WorkerRequest("generatepopup", {
            LocX: locationX,
            LocZ: locationZ,
        });
    }

    RetrieveLocationAudit (): Promise<string> {
        return this._WorkerRequest("getlocaudit", {});
    }
}
